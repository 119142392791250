import { useState } from 'react'

const sqfts = [
  { s: 1249 },
  { s: 1499 },
  { s: 1799 },
  { s: 2099 },
  { s: 2399 },
  { s: 2699 },
  { s: 2999 },
  { s: 3299 },
  { s: 3699 },
  { s: 3999, d: { s: '3700+' }},
]

const sqLabel = (sq) => sq.d ? `${sq.d.s}` : sq.s
const sqShow = (sq, sqPrev) => 
  sq.d
    ? `${sq.d.s}`
    : `${(sqPrev ? sqPrev.s : -1) + 1} to ${sq.s}`

const transforms = [
  { l: 'Move in/out cleaning', k: 'mv', f: x => x + 50,
    c: 'https://www.spotlesshome.co/move-in-move-out-clean/' },
  { l: 'Deep cleaning', k: 'd', f: x => x,
    c: 'https://www.spotlesshome.co/deep-clean/' },
  { l: 'Monthly cleaning', k: 'mo', f: x => x * .85,
    c: 'https://www.spotlesshome.co/standard-clean/' },
  { l: 'Biweekly cleaning', k: 'bw', f: x => x * .75,
    c: 'https://www.spotlesshome.co/standard-clean/' },
  { l: 'Weekly cleaning', k: 'w', f: x => x * .7,
    c: 'https://www.spotlesshome.co/standard-clean/' }
]

const fmt = x => ((x * 100) / 100).toFixed(2)

export const App = () => {
  const [sqftIx, setSqft] = useState(3)
  const sqft = () =>
    [ sqShow(sqfts[sqftIx - 1], sqfts[sqftIx - 2]),
      (sqfts[sqftIx - 1].s + 1) * 0.16
    ]
  return (
    <div>
      <div class="mb-5 text-center">
        <p>{sqft()[0]} square feet</p>
      </div>
      <input type="range" min={1} max={10} value={sqftIx}
        className="range-accent range" step={1}
        onChange={(e) => setSqft(e.target.value)} />
      <div className="w-full flex justify-between text-xs px-2">
        {sqfts.map(sq => (
          <span>{sqLabel(sq)} |</span>
        ))}
      </div>
      <div>
        {transforms.map(t => (
          <p>
            <a target="_blank" href={t.c}><b>{t.l}</b></a>:
            <span class="float-right text-success">${fmt(t.f(sqft()[1]))}</span>
          </p>
        ))}
      </div>
    </div>
  )
}
